
























import { Vue, Component } from 'vue-property-decorator'
import FolderIcon from '@/app/ui/assets/ics_o_folder.vue'
import { LocalStorage } from '@/app/infrastructures/misc'

@Component({
  components: {
    FolderIcon,
  },
})
export default class Search extends Vue {
  items = [
    {
      label: 'Tracking Package',
      path: 'package/',
      menu: 'all'
    },
    {
      label: 'Tracking Shipment',
      path: 'shipment/',
      menu: 'all',
    },
    {
      label: 'Search Courier by Keyword',
      path: '/manage-user-&-courier/manage-courier/?q=',
      menu: 'manage-courier',
    },
  ]

  created(): void {
    if (!this.$route.query.searchQuery) {
      this.$router
        .push('/')
        .catch(() => null)
    }
  }

  private checkAccessMenu(menu: string): boolean {
    const accessMenus = JSON.parse(
      LocalStorage.getLocalStorage(LocalStorage.ACCESS_MENU, true)
    )
    return menu === 'all' || accessMenus.includes(menu)
  }

  private setPathTo(path: string): string | (string | null)[] {
    return path + this.$route.query.searchQuery
  }

  private showAlert(): void {
    this.$notify({
      title: 'Forbidden Access',
      text: 'Your Account is not permitted to access this menu',
      type: 'error',
    })
  }
}
